import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#3E2723',
        secondary: '#4E342E',
        accent: '#8D6E63',
        error: colors.red.accent3,
        ripple: colors.grey.lighten1,
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
});
